import { Component, Input } from '@angular/core';

type CardList = Array<Card>;
type Card = Array<Line>;
type Line = {
  label: string;
  value: any;
  unit: string;
};

@Component({
  selector: 'app-total-tab',
  templateUrl: './total-tab.component.html',
  styleUrls: ['./total-tab.component.scss'],
})
export class TotalTabComponent {
  listOfCards?: CardList;

  @Input() set totalTab(value: any) {
    if (value) {
      this.listOfCards = this.formatAsListOfCards(value, 3);
    }
  }

  formatAsListOfCards(lines: Array<Line>, linesPerCard: number): CardList {
    let listOfCards: CardList = [];
    let cardCount = 0;

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      if (lineIndex % linesPerCard == 0) {
        listOfCards[cardCount++] = [];
      }

      const line: Line = {
        label: lines[lineIndex].label + ':',
        value: lines[lineIndex].value,
        unit: lines[lineIndex].unit,
      };

      if (line.value !== '') {
        listOfCards[cardCount - 1].push(line);
      }
    }
    return listOfCards;
  }
}
