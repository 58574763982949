<div class="tabNavigation">
  <div class="slider">
    <div *ngFor="let card of listOfCards" class="slider__item">
      <ng-container *ngFor="let line of card">
        <p *ngIf="shouldRenderLine(line)" class="slider__item--text">
          {{ line.label | translate }} <span>{{ line.value }}</span>
        </p>
        <p *ngIf="!shouldRenderLine(line)" class="slider__item--text">
          {{ line.label | translate }} <span>{{ line.value | translate }}</span>
        </p>
      </ng-container>
    </div>
  </div>
</div>
