<div class="trailList">
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img [src]="iconOperation" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>
            {{
              operationSidePanel?.operationType === other
                ? ('tasks.operation.navigation' | translate)
                : (operationSidePanel?.operationType | operationType | translate)
            }}
          </h1>
          <p>{{ 'monitoring.maps.title-operation' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="weatherInfo"
    *ngIf="operationSidePanel?.operationType !== other && operationSidePanel?.operationType !== isobus">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img [src]="iconAppliedVolume" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ appliedVolume }}/{{ 'global.hectare' | translate }}</h1>
          <p>{{ operationSidePanel?.operationType | appliedQuantity: 'name' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img [src]="iconSpeed" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ operationSidePanel?.speed | numberFormat: 1 }} {{ 'global.velocity-symbol' | translate }}</h1>
          <p>{{ 'global.speed-title' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo" *ngIf="operationSidePanel?.operationType !== other">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img [src]="iconOperator" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ staticMessageOperationSidePanel?.operatorName }}</h1>
          <p>{{ 'monitoring.maps.title-operator' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo" *ngIf="operationSidePanel?.operationType !== isobus">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img [src]="iconCoveredArea" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ operationSidePanel?.coveredArea | numberFormat: 1 }} {{ 'global.hectare' | translate }}</h1>
          <p>{{ 'monitoring.maps.title-area' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
