import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
})
export class TotalsComponent {
  @Input() currentTab: string;
  totalTabInfo: any;

  @Input() set totalTab(value: any) {
    if (value) this.totalTabInfo = value;
  }
}
