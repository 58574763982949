<div class="tabTotal">
  <div class="slider">
    <div *ngFor="let card of listOfCards" class="slider__item">
      <ng-container *ngFor="let line of card">
        <p class="slider__item--text">
          {{ line.label | translate }}
          <span
            >{{ line.value }} <ng-container *ngIf="line.unit"> {{ line.unit }}</ng-container></span
          >
        </p>
      </ng-container>
    </div>
  </div>
</div>
