import { Component, Input, OnChanges } from '@angular/core';
import {
  DynamicMessageOperationSidePanelModel,
  StaticMessageOperationSidePanelModel,
} from './operation-side-panel.model';
import { OpType, OperationType } from '../../enums/operation-type.enum';
import { AppliedQuantityPipe } from 'app/pipes/fleet-monitoring/applied-quantity.pipe';

@Component({
  selector: 'app-operation-side',
  templateUrl: './operation-side-panel.component.html',
  styleUrls: ['./operation-side-panel.component.scss'],
  providers: [AppliedQuantityPipe],
})
export class OperationSideComponent implements OnChanges {
  @Input() operationSidePanel: DynamicMessageOperationSidePanelModel;
  @Input()
  staticMessageOperationSidePanel: StaticMessageOperationSidePanelModel;
  operationType: OperationType;
  other = OpType.OTHER;
  isobus = OpType.ISOBUS;
  appliedQuantityPipe: AppliedQuantityPipe = new AppliedQuantityPipe();
  appliedVolume: string;
  iconOperation = '../../../assets/images/operation/pingoAgua.png';
  iconAppliedVolume = '../../../assets/images/operation/pingofolha.png';
  iconSpeed = '../../../assets/images/operation/marcador-tempo.png';
  iconOperator = '../../../assets/images/operation/produtor.svg';
  iconCoveredArea = '../../../assets/images/operation/icone-geral-infos.png';
  iconRPM = '../../../../../assets/images/operation/marcador-tempo.png';

  constructor() {
    this.formatUnits();
  }

  ngOnChanges() {
    this.formatUnits();
  }

  formatUnits() {
    let valueOperation = this.operationSidePanel?.appliedVolume.toFixed(1);
    let unitOperation = this.appliedQuantityPipe.transform(
      this.operationSidePanel?.operationType,
      'unit',
    );
    this.appliedVolume = valueOperation + unitOperation;
  }
}
