<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <div class="panel__flex">
        <div class="panel__controls">
          <div class="hidden__mobile">
            <ng-container>
              <app-button
                (btnClick)="goBack()"
                type="button"
                color="return"
                icon="agres-return"
                title="{{ 'global.back' | translate }}"></app-button>
            </ng-container>
          </div>
          <div class="combobox-container">
            <select id="device-select" [(ngModel)]="selectedDevice" (change)="onDeviceSelect()">
              <option *ngFor="let device of devices" [value]="device.deviceDesignator">
                {{ device.deviceDesignator }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section>
  <app-trail
    [receivedDeviceInfo]="deviceInfo"
    [initialArray]="circles"
    [currentTab]="currentTab"
    [operationSidePanel]="operationSidePanelInfo"
    [machineTab]="machineTabInfo"
    [navigationTab]="navigationTabInfo"
    [operationTab]="operationTabInfo"
    [totalTab]="totalTabInfo"
    [staticMessageOperationTabInfo]="staticMessageOperationTabInfo"
    [staticMessageOperationSidePanel]="staticMessageOperationSidePanelInfo"
    (processingComplete)="onProcessingComplete()">
  </app-trail>
</section>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
