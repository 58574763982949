import { Component, Input } from '@angular/core';
import { NavigationTabModel } from './navigation-tab.model';

type CardList = Array<Card>;
type Card = Array<Line>;
type Line = {
  label: string;
  value: any;
};

enum GpsSource {
  InvalidSource0 = 0,
  SteeringDemoMode = 1,
  GpsDemoMode = 2,
  InvalidSource3 = 3,
  TerrainCompensator = 4,
  ANR20SmartAntenna = 5,
  NMEA0183 = 6,
  NMEA2000 = 7,
  ANR30SmartAntenna = 8,
}

enum PositionType {
  FixNotAvailable = 0,
  SinglePoint = 1,
  PseudorangeDifferential = 2,
  NotApplicable = 3,
  RTKFixed = 4,
  RTKFloat = 5,
  DeadReckoning = 6,
  ManualInput = 7,
  SimulatorMode = 8,
  WAAS = 9,
}

@Component({
  selector: 'app-navigation-tab',
  templateUrl: './navigation-tab.component.html',
  styleUrls: ['./navigation-tab.component.scss'],
})
export class NavigationTabComponent {
  navigation: NavigationTabModel;
  listOfCards?: CardList;
  lines?: Line[];

  @Input() set navigationTab(value: NavigationTabModel) {
    if (value) {
      this.navigation = value;
    }
  }

  constructor() {
    this.updateListOfCards();
  }

  ngOnChanges() {
    this.updateListOfCards();
  }

  private updateListOfCards() {
    this.updateValues();
    if (this.lines) {
      this.listOfCards = this.formatAsListOfCards(this.lines, 3);
    }
  }

  formatAsListOfCards(lines: Array<Line>, linesPerCard: number): CardList {
    let listOfCards: CardList = [];
    let cardCount = 0;

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      if (lineIndex % linesPerCard == 0) {
        listOfCards[cardCount++] = [];
      }

      const line: Line = {
        label: lines[lineIndex].label,
        value: lines[lineIndex].value,
      };

      if (line.value !== '') {
        listOfCards[cardCount - 1].push(line);
      }
    }
    return listOfCards;
  }

  private getGpsSourceName(value: GpsSource): string {
    switch (value) {
      case GpsSource.InvalidSource0:
      case GpsSource.InvalidSource3:
        return 'monitoring.tabs.navigation.label-gps-source-invalid-source';
      case GpsSource.SteeringDemoMode:
        return 'monitoring.tabs.navigation.label-gps-source-steering-demo-mode';
      case GpsSource.GpsDemoMode:
        return 'monitoring.tabs.navigation.label-gps-source-gps-demo-mode';
      case GpsSource.TerrainCompensator:
        return 'monitoring.tabs.navigation.label-gps-source-terrain-compensator';
      case GpsSource.ANR20SmartAntenna:
        return 'monitoring.tabs.navigation.label-gps-source-anr20-smart-antenna';
      case GpsSource.NMEA0183:
        return 'monitoring.tabs.navigation.label-gps-source-nmea0183';
      case GpsSource.NMEA2000:
        return 'monitoring.tabs.navigation.label-gps-source-nmea2000';
      case GpsSource.ANR30SmartAntenna:
        return 'monitoring.tabs.navigation.label-gps-source-anr30-smart-antenna';
      default:
        return 'monitoring.tabs.navigation.label-gps-source-unknown';
    }
  }

  private getPositionTypeDescription(value: PositionType): string {
    const positionTypeKeyMap: Record<PositionType, string> = {
      [PositionType.FixNotAvailable]:
        'monitoring.tabs.navigation.label-position-type-fix-not-available',
      [PositionType.SinglePoint]:
        'monitoring.tabs.navigation.label-position-type-single-point',
      [PositionType.PseudorangeDifferential]:
        'monitoring.tabs.navigation.label-position-type-pseudorange-differential',
      [PositionType.NotApplicable]:
        'monitoring.tabs.navigation.label-position-type-not-applicable',
      [PositionType.RTKFixed]:
        'monitoring.tabs.navigation.label-position-type-rtk-fixed',
      [PositionType.RTKFloat]:
        'monitoring.tabs.navigation.label-position-type-rtk-float',
      [PositionType.DeadReckoning]:
        'monitoring.tabs.navigation.label-position-type-dead-reckoning',
      [PositionType.ManualInput]:
        'monitoring.tabs.navigation.label-position-type-manual-input',
      [PositionType.SimulatorMode]:
        'monitoring.tabs.navigation.label-position-type-simulator-mode',
      [PositionType.WAAS]:
        'monitoring.tabs.navigation.label-position-type-waas',
    };

    return (
      positionTypeKeyMap[value] ||
      'monitoring.tabs.navigation.label-position-type-unknown'
    );
  }

  shouldRenderLine(line: any): boolean {
    return (
      line.label !== 'monitoring.tabs.navigation.label-types-of-position' &&
      line.label !== 'monitoring.tabs.navigation.label-gps-source' &&
      line.label !== 'monitoring.tabs.navigation.label-steering'
    );
  }

  private updateValues() {
    if (this.navigation?.deviceType === 'agronave') {
      this.lines = [
        {
          label: 'monitoring.tabs.navigation.label-steering',
          value:
            this.navigation?.isSteeringEngaged === true
              ? 'monitoring.tabs.navigation.label-engaged'
              : 'monitoring.tabs.navigation.label-disengaged',
        },
        {
          label: 'monitoring.tabs.navigation.label-latitude',
          value: this.navigation?.latitude,
        },
        {
          label: 'monitoring.tabs.navigation.label-longitude',
          value: this.navigation?.longitude,
        },
        {
          label: 'monitoring.tabs.navigation.label-elevation',
          value: this.navigation?.elevation,
        },
        {
          label: 'monitoring.tabs.navigation.label-satellites-used',
          value: this.navigation?.usedSatellites,
        },
        {
          label: 'monitoring.tabs.navigation.label-types-of-position',
          value: this.getPositionTypeDescription(this.navigation?.positionType),
        },
        {
          label: 'monitoring.tabs.navigation.label-gps-source',
          value: this.getGpsSourceName(this.navigation?.gpsSource),
        },
      ];
    }
  }
}
