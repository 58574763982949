import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FleetMonitoringService } from 'app/core/services/fleet-monitoring.service';
import {
  ValidationContract,
  ValidatorError,
} from 'app/core/validators/fluent-validator';
import { AppliedQuantityPipe } from 'app/pipes/fleet-monitoring/applied-quantity.pipe';
import { OperationTypePipe } from 'app/pipes/fleet-monitoring/operation-type.pipe';
import { NumberFormatPipe } from 'app/pipes/shared/number-format.pipe';
import { Location } from 'app/shared/models/location';
import { Preferences } from 'app/shared/models/preferences.model';
import { Weather } from 'app/shared/models/weather';
import { OperationTypeUtil } from 'app/utils/operationType.utils';
import { TrailClass, Trails } from 'app/utils/trail.utils';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  DeviceInfoModel,
  DeviceMessageModel,
  RealtimeDeviceStatus,
} from '../device/device.model';
import { DeviceService } from '../device/device.service';
import { OpType, OperationType } from '../enums/operation-type.enum';
import { MachineTabModel } from '../machines/machine-tab/machine-tab.model';
import { NavigationTabModel } from '../navigations/navigation-tab/navigation-tab.model';
import {
  DynamicMessageOperationSidePanelModel,
  StaticMessageOperationSidePanelModel,
} from '../operations/operation-side-panel/operation-side-panel.model';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from '../operations/operation-tab/operation-tab.model';

import { SectionState } from '../enums/section-state.enum';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

const USER_TRAIL_COLOR_PREFERENCE = 'fleet_management_preferenced_colors';
declare let google: any;

interface TrailColor {
  red: number;
  orange: number;
  yellow: number;
  lemonGreen: number;
  green: number;
}
interface TurfPoint {
  type: string;
  geometry: { type: string; coordinates: number[] };
  properties: Object;
}
interface DevicePath {
  lat: number;
  lng: number;
}
type Card = {
  label: string;
  value: any;
  img: string;
};

@Component({
  selector: 'app-trail',
  templateUrl: './trail.component.html',
  styleUrls: ['./trail.component.scss'],
  providers: [
    DeviceService,
    AppliedQuantityPipe,
    OperationTypePipe,
    NumberFormatPipe,
  ],
})
export class TrailComponent implements OnInit, OnDestroy, OnChanges {
  tractorIconHtml = '../../../assets/images/default/tractor.png';
  iconOperation = '../../../assets/images/operation/pingoAgua.png';
  iconAppliedVolume = '../../../assets/images/operation/pingofolha.png';
  iconSpeed = '../../../assets/images/operation/marcador-tempo.png';
  iconOperator = '../../../assets/images/operation/produtor.svg';
  iconCoveredArea = '../../../assets/images/operation/icone-geral-infos.png';
  iconRPM = '../../../../../assets/images/operation/marcador-tempo.png';
  iconTemperature = '../../../../../assets/images/weather/sol.png';
  iconRain = '../../../../../assets/images/weather/chuvacomsol.png';
  iconHumidity = '../../../../../assets/images/weather/chuva.png';
  iconWind = '../../../../../assets/images/weather/chuva-vento.png';
  imgSessionOpenPath = '../../../assets/images/default/aberto.png';
  imgSessionClosedPath = '../../../assets/images/default/fechado.png';
  navigationTabInfo: NavigationTabModel;
  totalTabInfo: any;
  operationTabInfo: DynamicMessageOperationTabModel;
  staticMessageOperationTab: StaticMessageOperationTabModel;
  staticOperationSidePanelInfo: StaticMessageOperationSidePanelModel;
  operationType: OperationType;
  appliedQuantityPipe = new AppliedQuantityPipe();
  operationTypePipe = new OperationTypePipe();
  numberFormatPipe = new NumberFormatPipe(this.translateService);
  operationTypeUtil = new OperationTypeUtil();
  appliedVolume: string;
  operationName: string;
  appliedQuantityName: string;
  cards?: Card[];
  marker = true;
  seeButtonSave: boolean;
  zoom = 2;
  trails: Trails[] = [];
  mapLat: number;
  mapLng: number;
  workStatus = RealtimeDeviceStatus.OFFLINE;
  trailColorValue: TrailColor = {
    red: 10.5,
    orange: 12.5,
    yellow: 15.7,
    lemonGreen: 20.8,
    green: 22.0,
  };
  location: Location = { latitude: null, longitude: null, elevation: null };
  colorGreaterThanZero = '';
  greaterThan = '';
  zoomTimeout: NodeJS.Timeout;
  zoomInterval: NodeJS.Timeout;
  trailInterval: NodeJS.Timeout;
  trailTimeout: NodeJS.Timeout;
  trailClass: TrailClass;
  pinClicked = true;
  implementWidth: number;
  instRate: number;
  numberOfSections: number | string;
  deviceAngle: number;
  sectionArr: string[] = [];
  trailColorArr: string[] = [];
  trailColorScale: string;
  sectionStatus: string;
  deviceTrailRightColor: string[] = [];
  deviceTrailLeftColor: string[] = [];
  trailColorSel = '#00cc00';
  trailHidden = false;
  preferences: Preferences = {
    red: this.trailColorValue.red,
    orange: this.trailColorValue.orange,
    yellow: this.trailColorValue.yellow,
    lemonGreen: this.trailColorValue.lemonGreen,
    green: this.trailColorValue.green,
  };
  sectionArrLeft: string[] = [];
  circles = [];
  secArray: any;
  messagesInterval: number;
  maxArrayLength = 360;
  pointSelected = false;
  pointStatus: string[];
  pointInstRate: any;
  pointCenter: any;
  pointRadius: any;
  lastevent: any;
  pointDate: any;
  gatewayAddress: string;
  validationContract: ValidationContract;
  zoomEnabled = true;
  previousDeviceMessage: DeviceMessageModel;
  currentDeviceId = 'deviceId';
  trailEnabled: boolean;
  apiLoaded!: boolean;
  allDevices;
  weather: Weather;
  tempF: number;
  weatherInterval: NodeJS.Timeout;
  deviceId: string;
  public showModalSubject: Subject<boolean> = new Subject();
  public showModal: boolean;
  readonly refreshIntervalInSeconds = 30;
  isOnlineInterval: NodeJS.Timeout;
  colorPreferences: Preferences;

  @Input() operationSidePanelInfo: DynamicMessageOperationSidePanelModel;
  @Input() staticMessageOperationTabInfo: StaticMessageOperationTabModel;
  @Input() currentTab: string;
  pointVelocity: any;
  currentOperationType: OperationType;

  @Input() set navigationTab(value: NavigationTabModel) {
    if (value) {
      this.navigationTabInfo = value;
    }
  }

  @Input() set totalTab(value: any) {
    if (value) {
      this.totalTabInfo = value;
    }
  }

  @Input() set machineTab(value: MachineTabModel) {
    if (value) {
      this.machineTabInfo = value;
    }
  }

  machineTabInfo: MachineTabModel;

  @Input() set operationSidePanel(
    value: DynamicMessageOperationSidePanelModel,
  ) {
    if (value) {
      this.operationSidePanelInfo = value;
    }
  }

  @Input() set staticMessageOperationSidePanel(
    value: StaticMessageOperationSidePanelModel,
  ) {
    if (value) {
      this.staticOperationSidePanelInfo = value;
    }
  }

  @Input() set operationTab(value: DynamicMessageOperationTabModel) {
    if (value) {
      this.operationTabInfo = value;
      if (this.staticMessageOperationTabInfo !== undefined) {
        this.staticMessageOperationTabInfo.nozzlesPerSection.splice(
          this.operationTabInfo.numberOfSections,
        );
      }
    }
  }

  @Output() processingComplete = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private httpClient: HttpClient,
    private fleetMonitoringService: FleetMonitoringService,
    private datePipe: DatePipe,
    private readonly deviceService: DeviceService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.updateListOfCards();
    this.trailClass = new TrailClass();
    this.validationContract = new ValidationContract();
    this.loadGoogleMapsAPI();
  }
  ngOnDestroy() {
    google = null;
    clearInterval(this.weatherInterval);
    clearInterval(this.isOnlineInterval);
  }

  ngOnInit() {
    this.setUserColorPreferences();
    this.deviceId = window.localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.DEVICE_ID,
    );

    if (this.deviceId !== null) {
      this.waitForCoordinates().then(() => {
        this.weatherData();
        this.startWeatherInterval();
      });

      this.isDeviceOnline();
    }
  }

  loadGoogleMapsAPI() {
    this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      )
      .subscribe((loaded: boolean) => {
        this.apiLoaded = loaded;
        this.cdr.detectChanges();
      });
  }

  private waitForCoordinates(): Promise<void> {
    return new Promise((resolve) => {
      const checkCoordinatesInterval = setInterval(() => {
        if (
          this.location.latitude !== null &&
          this.location.longitude !== null
        ) {
          clearInterval(checkCoordinatesInterval);
          resolve();
        }
      }, 1000);
    });
  }

  private startWeatherInterval() {
    this.weatherInterval = setInterval(() => {
      if (this.location.latitude !== null && this.location.longitude !== null) {
        this.weatherData();
      }
    }, 900_000);
  }

  goBack() {
    this.router.navigate(['/']);
  }

  isDeviceOnline() {
    const checkDeviceStatus = (response: any[]) => {
      const status = response.filter(
        (device) => device.deviceId === this.deviceId,
      )[0];

      if (status.timestamp == null) {
        return false;
      }

      const date: string = new Date(
        Date.now() - this.refreshIntervalInSeconds * 1000,
      ).toISOString();
      const timeNow: number = Date.parse(date);

      if (status.timestamp > timeNow) {
        this.showModal = false;
        this.showModalSubject.next(false);
      } else {
        this.showModal = true;
        this.showModalSubject.next(true);
      }
    };

    this.isOnlineInterval = setInterval(() => {
      if (this.gatewayAddress === 'agronave') {
        this.deviceService
          .getAllAgronaveDevicesRealtime()
          .subscribe(checkDeviceStatus);
      } else if (this.gatewayAddress === 'Isolink-Dongle') {
        this.deviceService.getAllDeviceRealtime().subscribe(checkDeviceStatus);
      }
    }, 15000);
  }

  ngOnChanges() {
    this.updateListOfCards();
  }

  @Input() set initialArray(value: any) {
    if (value.length > 0) {
      if (this.circles) {
        this.circles = value;
        this.location.latitude =
          this.circles[this.circles.length - 1].center.lat;
        this.location.longitude =
          this.circles[this.circles.length - 1].center.lng;
        this.deviceZoom();
        this.processingComplete.emit();
      }
    }
  }

  @Input() set receivedDeviceInfo(value: DeviceInfoModel) {
    const {
      id: deviceId,
      gwAddr: gateway,
      status: deviceStatus,
      secArray,
      showTrail,
      hidden,
      opType,
      implWidth,
      instRate,
      latitude,
      longitude,
      timestamp,
      secState,
      speed,
    } = value || {
      id: undefined,
      gwAddr: undefined,
      status: undefined,
      secArray: undefined,
      showTrail: undefined,
      hidden: undefined,
      opType: undefined,
      implWidth: undefined,
      instRate: undefined,
      latitude: undefined,
      longitude: undefined,
      timestamp: undefined,
      secState: undefined,
      speed: undefined,
    };

    if (deviceId && this.circles) {
      this.workStatus = deviceStatus;
      this.secArray = secArray;
      this.currentDeviceId = deviceId;
      this.gatewayAddress = gateway;
      this.currentOperationType = opType;
      if (this.currentDeviceId !== deviceId) {
        clearTimeout(this.trailTimeout);
        clearInterval(this.trailInterval);
        if (this.workStatus === RealtimeDeviceStatus.OFFLINE) {
          clearInterval(this.zoomInterval);
          clearTimeout(this.zoomTimeout);
          this.zoomEnabled = false;
          this.marker = false;
          this.zoom = 2;
        } else {
          this.trails = [];
          this.trailEnabled = true;
          this.setLocation(latitude, longitude, timestamp);
          this.marker = true;
          this.zoomEnabled = true;
          this.createTrail(
            latitude,
            longitude,
            timestamp,
            speed,
            secState,
            gateway,
          );
          this.trailEnabled = false;
        }
      } else if (this.currentDeviceId === deviceId) {
        this.setLocation(latitude, longitude, timestamp);
        this.implementWidth = implWidth;
        this.instRate = instRate;

        this.marker = true;
        this.zoomEnabled = true;
        this.createTrail(
          latitude,
          longitude,
          timestamp,
          speed,
          secState,
          gateway,
        );
        this.trailEnabled = false;
      }
      this.currentDeviceId = deviceId;
    } else {
      this.trails = [];
    }
  }

  private updateListOfCards() {
    this.formatUnits();
    this.updateValuesOfCards();
    this.cards = this.modifyLinesPerOperation(this.cards);
  }

  modifyLinesPerOperation(lines: Card[]): Card[] {
    let copyOfLines = [...lines];

    if (
      this.operationTypeUtil.isOperationOther(
        this.operationSidePanelInfo?.operationType,
      )
    ) {
      copyOfLines = this.removeSpecificLinesForOpTypeOutros(copyOfLines);
    }

    return copyOfLines;
  }

  removeSpecificLinesForOpTypeOutros(lines: Card[]): Card[] {
    lines.splice(1, 1);

    return lines;
  }

  private formatUnits() {
    let valueOperation = this.operationSidePanelInfo?.appliedVolume.toFixed(1);
    let unitOperation = this.appliedQuantityPipe.transform(
      this.operationSidePanelInfo?.operationType,
      'unit',
    );
    this.appliedVolume =
      valueOperation + this.translateService.instant(unitOperation);
    this.operationName = this.operationTypePipe.transform(
      this.operationSidePanelInfo?.operationType,
    );
    this.appliedQuantityName = this.appliedQuantityPipe.transform(
      this.operationSidePanelInfo?.operationType,
      'name',
    );
  }

  private updateValuesOfCards() {
    this.cards = [
      {
        label: 'monitoring.maps.title-operation',
        value:
          this.gatewayAddress === 'agronave'
            ? this.translateService.instant('tasks.operation.navigation')
            : this.translateService.instant(this.operationName),
        img: this.iconOperation,
      },
      {
        label: this.appliedQuantityName,
        value:
          this.appliedVolume +
          '/' +
          this.translateService.instant('global.hectare'),
        img: this.iconAppliedVolume,
      },
      {
        label: 'global.speed-title',
        value:
          this.numberFormatPipe.transform(
            this.operationSidePanelInfo?.speed,
            1,
          ) + this.translateService.instant('global.speed-symbol'),
        img: this.iconSpeed,
      },
      {
        label: 'monitoring.maps.title-area',
        value:
          this.numberFormatPipe.transform(
            this.operationSidePanelInfo?.coveredArea,
            1,
          ) + this.translateService.instant('global.hectare'),
        img: this.iconCoveredArea,
      },
      {
        label: 'monitoring.maps.title-temperature',
        value:
          this.numberFormatPipe.transform(this.weather?.Temperatura, 1) +
          this.translateService.instant('global.temperature-symbol') +
          ' ' +
          this.translateService.instant('monitoring.maps.title-temperature'),
        img: this.iconTemperature,
      },
      {
        label: 'monitoring.maps.title-rain',
        value:
          this.numberFormatPipe.transform(this.weather?.Precipitacao, 0) +
          this.translateService.instant('global.percentage') +
          ' ' +
          this.translateService.instant('monitoring.maps.title-rain'),
        img: this.iconRain,
      },
      {
        label: 'monitoring.maps.title-humidity',
        value:
          this.numberFormatPipe.transform(this.weather?.Umidade, 0) +
          this.translateService.instant('global.percentage') +
          ' ' +
          this.translateService.instant('monitoring.maps.title-humidity'),
        img: this.iconHumidity,
      },
      {
        label: 'global.speed-wind',
        value:
          this.numberFormatPipe.transform(this.weather?.Vento, 2) +
          ' ' +
          this.translateService.instant('global.velocity-symbol') +
          ' ' +
          this.translateService.instant('global.speed-wind'),
        img: this.iconWind,
      },
    ];

    if (this.gatewayAddress !== 'agronave') {
      this.cards.push({
        label: 'monitoring.maps.title-operator',
        value: this.staticOperationSidePanelInfo?.operatorName,
        img: this.iconOperator,
      });
    }
  }

  setLocation(latitude: number, longitude: number, timestamp: number) {
    if (latitude !== 0 && longitude !== 0 && timestamp !== 0) {
      if (timestamp > this.circles[this.circles.length - 1].timestamp) {
        this.location.latitude = latitude;
        this.location.longitude = longitude;
      }
    }
  }

  createTrail(
    latitude: number,
    longitude: number,
    timestamp: number,
    speed: number,
    secState: string,
    gwAddr: string,
  ) {
    if (latitude !== 0 && longitude !== 0) {
      if (this.instRate !== undefined) {
        if (this.circles.length > this.maxArrayLength) {
          this.circles.shift();
        }
        this.circles.push({
          center: {
            lat: this.location.latitude,
            lng: this.location.longitude,
          },
          radius: this.implementWidth / 2,
          instRate: this.instRate,
          sec_state: secState,
          sectionArr: this.secArray,
          gwAddr: gwAddr,
          selected: false,
          timestamp: timestamp,
          velocity: speed,
        });
      } else {
        if (this.circles.length > this.maxArrayLength) {
          this.circles.shift();
        }
        this.circles.push({
          center: {
            lat: this.location.latitude,
            lng: this.location.longitude,
          },
          radius: this.implementWidth / 2,
          instRate: 0,
          sec_state: secState,
          sectionArr: this.secArray,
          gwAddr: gwAddr,
          selected: false,
          timestamp: timestamp,
          velocity: speed,
        });
      }

      this.deviceZoom();
      if (this.pinClicked === true) {
        this.mapLat = this.location.latitude;
        this.mapLng = this.location.longitude;
      }

      this.processingComplete.emit();
    } else {
      this.processingComplete.emit();
    }
  }

  deviceZoom() {
    this.zoomTimeout = setTimeout(() => {
      if (this.zoom < 5) {
        if (this.zoomEnabled) {
          this.mapLat = this.location.latitude;
          this.mapLng = this.location.longitude;
        }
      }

      this.zoomInterval = setInterval(() => {
        if (this.zoomEnabled) {
          this.zoom <= 17 ? this.zoom++ : clearInterval(this.zoomInterval);
        }
      }, 0);
    }, 0);
  }

  circleClick(event) {
    const clearLastPoint = this.circles.filter(
      (item) => item.selected === true,
    );
    if (clearLastPoint.length > 0) {
      clearLastPoint[0].selected = false;
    }

    const point = this.circles[event];
    const status: string[] = [];
    for (let i = 0; i < point.sectionArr.length; i++) {
      if (point.sectionArr[i] === SectionState.OPEN) {
        status.push(this.imgSessionOpenPath);
      } else {
        status.push(this.imgSessionClosedPath);
      }
    }
    point.selected = true;
    this.pointSelected = true;
    this.pointStatus = status;
    this.pointInstRate = point.instRate.toFixed(2);
    this.pointVelocity = point.velocity;
    if (this.gatewayAddress === 'Isolink-Dongle') {
      this.pointDate = this.datePipe.transform(
        point.timestamp * 1000,
        'dd/MM/yyyy - HH:mm:ss',
      );
    } else {
      this.pointDate = this.datePipe.transform(
        point.timestamp,
        'dd/MM/yyyy - HH:mm:ss',
      );
    }
  }

  closeInfos() {
    const clearLastPoint = this.circles.filter(
      (item) => item.selected === true,
    );
    if (clearLastPoint.length > 0) {
      clearLastPoint[0].selected = false;
    }
    this.pointSelected = false;
  }

  markerClick() {
    if (this.pinClicked === true) {
      this.pinClicked = false;
    } else {
      this.pinClicked = true;
    }
  }

  setUserColorPreferences() {
    this.colorPreferences = {
      red: 10.5,
      orange: 12.5,
      yellow: 15.7,
      lemonGreen: 20.8,
      green: 22,
    };

    if (this.colorPreferences) {
      Object.keys(this.trailColorValue).forEach((scale: string) => {
        this.trailColorValue[scale] = this.colorPreferences[scale];
        this.preferences[scale] = this.colorPreferences[scale];
      });
    }
  }

  changeColorInputForClear() {
    $('.legendText').each(function (index: number, element: HTMLElement) {
      $(element).removeClass('error-class');
    });
  }

  validationValuesPreferences(preferences: Preferences): boolean {
    this.greaterThan = '';
    this.validationContract.isGreaterThanZero(
      preferences.green,
      'green',
      'green ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      preferences.lemonGreen,
      'lemonGreen',
      'lemonGreen ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      preferences.yellow,
      'yellow',
      'yellow ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      preferences.orange,
      'orange',
      'orange ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      preferences.red,
      'red',
      'red ' + this.colorGreaterThanZero,
    );
    this.validationContract.isLowerThan(
      preferences.lemonGreen,
      preferences.green,
      'lemonGreen',
      'lemonGreen ' + this.greaterThan + ' green',
    );
    this.validationContract.isLowerThan(
      preferences.yellow,
      preferences.lemonGreen,
      'yellow',
      'yellow ' + this.greaterThan + ' lemonGreen',
    );
    this.validationContract.isLowerThan(
      preferences.orange,
      preferences.yellow,
      'orange',
      'orange ' + this.greaterThan + ' yellow',
    );
    this.validationContract.isLowerThan(
      preferences.red,
      preferences.orange,
      'red',
      'red ' + this.greaterThan + ' orange',
    );
    return this.validationContract.isValid();
  }

  changeColorInputForError(property: string) {
    $(`#${property}`).addClass('error-class');
  }

  saveTx() {
    const colorPreferencesValid = {
      red: Number(this.trailColorValue.red),
      orange: Number(this.trailColorValue.orange),
      yellow: Number(this.trailColorValue.yellow),
      lemonGreen: Number(this.trailColorValue.lemonGreen),
      green: Number(this.trailColorValue.green),
    };
    this.validationContract.clear();
    this.changeColorInputForClear();
    if (this.validationValuesPreferences(colorPreferencesValid)) {
      this.colorPreferences = colorPreferencesValid;
    } else {
      const errors: ValidatorError[] = this.validationContract.getErrors();

      errors.forEach((erro: ValidatorError) => {
        this.changeColorInputForError(erro.property);
      });

      this.translateService
        .get('trail.inconsistent-values')
        .subscribe((res: string) => {
          this.translateService
            .get('trail.instant-rate')
            .subscribe((resInstante: string) => {
              this.toastr.error(res, resInstante);
            });
        });
    }
    this.seeButtonSave = false;
    setTimeout(() => {
      this.trails = [];
    }, 3000);
  }

  onSaveComplete(preferences: Preferences) {
    this.preferences = {
      red: preferences.red,
      orange: preferences.orange,
      yellow: preferences.yellow,
      lemonGreen: preferences.lemonGreen,
      green: preferences.green,
    };

    this.translateService
      .get('monitoring.tabs.operation.message-toaster-sucess-colors')
      .subscribe((res: string) => {
        this.translateService
          .get('trail.instant-rate')
          .subscribe((resInstante: string) => {
            this.toastr.success(res, resInstante);
          });
      });
  }

  weatherData() {
    if (this.location.latitude && this.location.longitude) {
      this.fleetMonitoringService
        .getWeather(this.location.latitude, this.location.longitude)
        .subscribe(
          (res: Weather) => {
            this.weather = res;
            this.tempF = (this.weather.Temperatura * 9) / 5 + 32;
          },
          () => {
            this.translateService
              .get('error.500.error-occurred-try-again')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
        );
    }
  }

  changeColor(
    selected: boolean,
    instRate: number,
    secArray: Array<string>,
    gwAddr: string,
    opType: OperationType,
  ) {
    if (selected) {
      return '#556B61';
    } else if (this.isClose(secArray)) {
      return '#A1CAAE';
    } else if (this.isAgronave(gwAddr) || instRate === 0) {
      return '#1cbd52';
    } else {
      const thresholds = [
        { color: '#FF0000', value: this.colorPreferences.red },
        { color: '#FFA500', value: this.colorPreferences.orange },
        { color: '#FFFF00', value: this.colorPreferences.yellow },
        { color: '#ADFF2F', value: this.colorPreferences.lemonGreen },
        { color: '#008000', value: this.colorPreferences.green },
      ];

      for (let i = 0; i < thresholds.length - 1; i++) {
        const lower = thresholds[i];
        const upper = thresholds[i + 1];

        if (instRate >= lower.value && instRate <= upper.value) {
          const ratio = (instRate - lower.value) / (upper.value - lower.value);
          return this.interpolateColor(lower.color, upper.color, ratio);
        }
      }
    }
  }

  hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }

  interpolateColor(color1: string, color2: string, ratio: number): string {
    const rgb1 = this.hexToRgb(color1);
    const rgb2 = this.hexToRgb(color2);

    const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * ratio);
    const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * ratio);
    const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * ratio);

    return this.rgbToHex(r, g, b);
  }

  isClose(secArray) {
    let isClose = true;
    for (const section of secArray) {
      if (section === '1') {
        isClose = false;
      }
    }
    return isClose;
  }

  isAgronave(gwAddr: string) {
    if (gwAddr === 'agronave') {
      return true;
    } else {
      return false;
    }
  }

  hasCoordinates(): boolean {
    return (
      this.location.latitude != undefined &&
      this.location.longitude != undefined
    );
  }
}
