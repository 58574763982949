import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  DeviceInfoModel,
  DeviceMessageModel,
  RealtimeDeviceStatus,
  convertToRealtimeStatus,
} from 'app/modules/gestao-operacao/fleet-monitoring/device/device.model';
import { MachineTabModel } from 'app/modules/gestao-operacao/fleet-monitoring/machines/machine-tab/machine-tab.model';
import { NavigationTabModel } from 'app/modules/gestao-operacao/fleet-monitoring/navigations/navigation-tab/navigation-tab.model';
import { DeviceService } from '../device/device.service';
import {
  DynamicMessageOperationSidePanelModel,
  StaticMessageOperationSidePanelModel,
} from '../operations/operation-side-panel/operation-side-panel.model';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from '../operations/operation-tab/operation-tab.model';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { OpType } from '../enums/operation-type.enum';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-realtime-device-monitoring',
  templateUrl: './realtime-device-monitoring.component.html',
  styleUrls: ['./realtime-device-monitoring.component.scss'],
})
export class RealtimeDeviceMonitoringComponent implements OnInit, OnDestroy {
  deviceInfo: DeviceInfoModel;
  deviceInfoArray: DeviceInfoModel[] = [];
  circles = [];
  oldMessages = [];
  currentTab = 'operation';
  operationSidePanelInfo: DynamicMessageOperationSidePanelModel;
  staticMessageOperationSidePanelInfo: StaticMessageOperationSidePanelModel;
  machineTabInfo: MachineTabModel;
  totalTabInfo: any;
  navigationTabInfo: NavigationTabModel;
  operationTabInfo: DynamicMessageOperationTabModel;
  staticMessageOperationTabInfo: StaticMessageOperationTabModel;
  private deviceInterval: NodeJS.Timeout;
  private agronaveDeviceUpdateInterval: NodeJS.Timeout;
  private agronaveTaskUpdateInterval: NodeJS.Timeout;

  private enableProcessing = true;
  private getLastMessageOnly = false;
  lastmessage: any;
  lengthArrayOfMessages: number;
  timestamps = [];
  machineTabHidden = true;
  updateCircles: NodeJS.Timeout;

  ecuDDIMap = new Map([
    ['lifetimeTotalArea', 271],
    ['lifetimeEffectiveTotalDistance', 272],
    ['lifetimeEffectiveTotalTime', 274],
    ['lifetimeApplicationTotalVolume', 325],
    ['lifetimeApplicationTotalMass', 266],
    ['lifetimeApplicationTotalCount', 267],
  ]);

  taskDDIMap = new Map([
    ['effectiveTotalDistance', 117],
    ['totalArea', 116],
    ['effectiveTotalTime', 119],
    ['applicationTotalVolumeAsL', 80],
    ['applicationTotalMassInKg', 81],
    ['applicationTotalCount', 82],
  ]);

  ratesDDIMap = new Map([
    ['actualVolumePerAreaApplicationRateAsMm3DivM2', 2],
    ['actualCountPerAreaApplicationRate', 12],
    ['actualMassPerAreaApplicationRate', 7],
  ]);

  barSizeDDIMap = new Map([['actualWorkingWidth', 67]]);

  DDIMap: { [x: string]: any };
  devices = [];
  selectedDevice: string;
  device: any;
  currentRate: Number = 0;
  currentRateUnit: string = '';
  sectState: Number[];
  implementWidth: Number = 10;

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    public _location: Location,
    private readonly loadingAgresSpinner: AgresSpinnerService,
    private readonly translateService: TranslateService,
    private http: HttpClient,
  ) {
    this.DDIMap = {
      ...Object.fromEntries(this.createDDIMap(this.ecuDDIMap)),
      ...Object.fromEntries(this.createDDIMap(this.taskDDIMap)),
      ...Object.fromEntries(this.createDDIMap(this.ratesDDIMap)),
      ...Object.fromEntries(this.createDDIMap(this.barSizeDDIMap)),
    };
  }

  ngOnInit(): void {
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinner.toShow(res);
    });
    this.route.params.subscribe((params) => {
      if (params.type == 'agronave') {
        this.fetchAgronaveUpdateMsgs(params.id);
        this.fetchAgronaveTaskInfoMsgs(params.id);
      } else {
        this.fetchRealtimeMsgsPeriodically(params.id);
      }
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.deviceInterval);
    clearTimeout(this.agronaveDeviceUpdateInterval);
    clearTimeout(this.agronaveTaskUpdateInterval);
  }

  onDeviceSelect() {
    this.device = this.devices.find(
      (device) => device.deviceDesignator === this.selectedDevice,
    );
    this.getLastMessageOnly = false;
  }

  createDDIMap(map) {
    return new Map(
      [...map].map(([key, value]) => [
        value.toString(16).padStart(4, '0').toUpperCase(),
        key,
      ]),
    );
  }

  toHexSigned(value) {
    let hex = (value & 0xffff).toString(16).toUpperCase();
    return hex.padStart(4, '0');
  }

  fetchAgronaveUpdateMsgs(deviceId: string) {
    this.agronaveDeviceUpdateInterval = setInterval(() => {
      this.deviceService.getUpdateMessages(deviceId).subscribe(
        (deviceMessage) => {
          this.parseAgronaveRealtimeMsg(deviceMessage.messages);
        },
        (err: any) => {
          console.error(err);
        },
      );
    }, 3000);
  }

  fetchAgronaveTaskInfoMsgs(deviceId: string) {
    this.agronaveTaskUpdateInterval = setInterval(() => {
      this.deviceService.getTaskInfoMessages(deviceId).subscribe(
        (deviceMessage) => {
          this.parseAgronaveStaticMsg(deviceId, deviceMessage.messages);
        },
        (err: any) => {
          console.error(err);
        },
      );
    }, 3000);
  }

  parseAgronaveStaticMsg(deviceId, deviceMessages) {
    const taskInfoMessage = this.decodeBase64ToJson(deviceMessages[0]);
    this.staticMessageOperationTabInfo = {
      cropName: taskInfoMessage.field_name,
      cropType: '',
      eventName: taskInfoMessage.task_name,
      nozzlesPerSection: [],
      numberOfNozzles: '0',
      spacingPerNozzles: 0,
      farmName: taskInfoMessage.farm_name,
      machineName: taskInfoMessage.machine,
      sectorName: taskInfoMessage.sector,
    };
    this.staticMessageOperationSidePanelInfo = {
      operatorName: taskInfoMessage.operator,
    };

    this.deviceService.getTaskDDOPMessages(deviceId).subscribe(
      (ddop) => {
        if (ddop && ddop.messages && ddop.messages[0]) {
          this.deviceService.ddopParsed(ddop.messages[0]).subscribe(
            (response: Array<any>) => {
              this.devices = response;
              this.selectedDevice = this.devices[0].deviceDesignator;
              this.device = this.devices[0];
              clearTimeout(this.agronaveTaskUpdateInterval);
            },
            (error) => {
              console.error('Error during DDOP parser POST:', error);
            },
          );
        } else {
          console.error('DDOP messages not found or invalid format.');
        }
      },
      (err: any) => {
        console.error('Error fetching DDOP messages:', err);
      },
    );
  }

  applyScaleAndDecimals(
    value: number,
    scale: number,
    numberOfDecimals: number,
  ) {
    const scaledValue = value * scale;
    return scaledValue.toFixed(numberOfDecimals);
  }

  parseStateValues(sections): number[] {
    const sectionStates: number[] = [];
    for (const section of sections) {
      for (let i = 0; i < 32; i += 2) {
        let state = (Number(section.value) >>> i) & 0x03;
        if (state > 1) {
          state = 0;
        }

        sectionStates.push(state);
      }
    }
    return sectionStates;
  }

  parseAgronaveRealtimeMsg(deviceMessages: any) {
    if (
      !this.enableProcessing ||
      !this.device ||
      !Array.isArray(deviceMessages) ||
      deviceMessages.length === 0
    ) {
      return;
    }

    if (this.getLastMessageOnly && this.lastmessage) {
      this.processSingleMessage(deviceMessages[deviceMessages.length - 1]);
    } else {
      this.oldMessages = [];
      deviceMessages.forEach((message) => this.processMessage(message));
      this.updateLastMessage(
        this.decodeBase64ToJson(deviceMessages[deviceMessages.length - 1].value)
          .device_update,
      );
      this.circles = this.oldMessages;
      this.enableProcessing = false;
    }
  }

  private processSingleMessage(deviceMessage: any) {
    this.loadingAgresSpinner.toHide();
    const decodedMessage = this.decodeBase64ToJson(deviceMessage.value);
    const deviceUpdateMessage = decodedMessage.device_update;
    const taskUpdateMessage = decodedMessage.task_update;
    if (deviceUpdateMessage.timestamp >= this.lastmessage.timestamp) {
      this.updateDeviceData(deviceUpdateMessage, taskUpdateMessage);
      this.deviceInfo = this.createDeviceInfo(deviceUpdateMessage);
      this.updateLastMessage(deviceUpdateMessage);
    }
  }

  private processMessage(deviceMessage: any) {
    const decodedMessage = this.decodeBase64ToJson(deviceMessage.value);
    const deviceUpdateMessage = decodedMessage.device_update;
    const taskUpdateMessage = decodedMessage.task_update;
    this.updateDeviceData(deviceUpdateMessage, taskUpdateMessage);
    this.saveHistoricalData(deviceUpdateMessage);
  }

  private updateDeviceData(deviceUpdateMessage: any, taskUpdateMessage: any) {
    const ddiValues = this.extractDDIValues(taskUpdateMessage);
    const dpdValues = this.mapDDIToDPD(ddiValues);

    this.updateImplementWidth(dpdValues);
    this.updateCurrentRate(dpdValues);
    this.updateSectState(dpdValues);

    this.totalTabInfo = this.calculateTotals(taskUpdateMessage);
    this.updateOperationInfo(deviceUpdateMessage);
    this.updateMachineInfo(deviceUpdateMessage);
    this.updateNavigationInfo(deviceUpdateMessage);
  }

  private extractDDIValues(ddi: any): any[] {
    const dets = new Set(this.device.Dets);

    return Object.entries(ddi)
      .map(([key, value]) => {
        const [hexPart, det] = key.split('_');
        const label = this.determineLabel(hexPart);

        if (!label || !dets.has(det)) return null;

        const type = this.determineType(label);
        return { label, type, dpd: key, value };
      })
      .filter(Boolean);
  }

  private determineLabel(hexPart: string): string | null {
    return (
      this.DDIMap[hexPart] ||
      (parseInt(hexPart, 16) >= parseInt('A1', 16) &&
      parseInt(hexPart, 16) <= parseInt('B0', 16)
        ? 'sectState'
        : null)
    );
  }

  private determineType(label: string): string | null {
    if (this.ratesDDIMap.has(label)) return 'rate';
    if (this.ecuDDIMap.has(label)) return 'lifetime';
    if (this.taskDDIMap.has(label)) return 'task';
    if (this.barSizeDDIMap.has(label)) return 'barSize';
    if (label === 'sectState') return 'sectState';
    return null;
  }

  private mapDDIToDPD(ddiValues: any[]): any[] {
    return ddiValues
      .map((item) => {
        const dpdData = this.device.dpds[item.dpd];
        if (dpdData) {
          const finalValue = this.applyScaleAndDecimals(
            item.value as number,
            dpdData.scale,
            dpdData.numberOfDecimals,
          );
          return {
            label: dpdData.label,
            type: item.type,
            value: finalValue,
            unit: dpdData.unit,
          };
        }
        return null;
      })
      .filter(Boolean);
  }

  private updateCurrentRate(dpdValues: any[]) {
    const filteredRate = dpdValues.find((item) => item.type === 'rate');
    if (filteredRate) {
      this.currentRate = Number(filteredRate.value);
      this.currentRateUnit = filteredRate.unit;
    }
  }

  private updateSectState(dpdValues: any[]) {
    const filteredSectState = dpdValues.filter(
      (item) => item.type === 'sectState',
    );
    if (filteredSectState.length > 0) {
      this.sectState = this.parseStateValues(filteredSectState).slice(
        0,
        this.device.references.Section.length,
      );
    }
  }

  private updateImplementWidth(dpdValues: any[]) {
    const barWidth = this.device?.properties?.maximumWorkingWidth ?? null;
    if (barWidth) {
      this.implementWidth = barWidth[0];
    } else {
      const barSizes = dpdValues.filter((item) => item.type === 'barSize');
      if (barSizes.length > 0) {
        const maxBarSize = barSizes.reduce((max, current) => {
          return parseInt(current.value) > parseInt(max.value) ? current : max;
        });
        const value = parseInt(maxBarSize.value);
        switch (maxBarSize.unit) {
          case 'mm':
            this.implementWidth = value / 1000;
            break;
          case 'cm':
            this.implementWidth = value / 100;
            break;
          case 'm':
            this.implementWidth = value;
            break;
          default:
            this.implementWidth = value;
            break;
        }
      }
    }
  }

  private calculateTotals(taskUpdateMessage: any): any[] {
    return Object.keys(this.device.totals).reduce((result, key) => {
      if (taskUpdateMessage[key] !== undefined) {
        const { label, scale, numberOfDecimals, unit } =
          this.device.totals[key];
        const value = this.applyScaleAndDecimals(
          taskUpdateMessage[key],
          scale,
          numberOfDecimals,
        );
        result.push({ label, unit, value });
      }
      return result;
    }, []);
  }

  private updateOperationInfo(deviceUpdateMessage: any) {
    this.operationSidePanelInfo = {
      speed: deviceUpdateMessage.speed,
      operationType: OpType.ISOBUS,
      appliedVolume: 0,
      coveredArea: 0,
      rpm: 0,
    };

    this.operationTabInfo = {
      implementWidth: this.implementWidth as number,
      operationType: OpType.ISOBUS,
      numberOfSections: this.device.references.Section.length,
      travelDistance: (deviceUpdateMessage.total_distance / 1000).toFixed(1),
      instRate: this.currentRate as number,
      instRateUnit: this.currentRateUnit,
      sectionArr: this.sectState.map((num) => String(num)),
      operationActiveTime: '0',
      operationSleepTime: '0',
      operationTimePercent: 0,
      operationTotalTime: '0',
    };
  }

  private updateMachineInfo(deviceUpdateMessage: any) {
    this.machineTabInfo = {
      speed: deviceUpdateMessage.speed,
      deviceId: deviceUpdateMessage.serial_number,
      batteryVoltage: 0,
      diskRpm: 0,
      engineType: '0',
      fuelLevel: 0,
      oilPressure: 0,
      rpm: 0,
      waterTemperature: 0,
    };
  }

  private updateNavigationInfo(deviceUpdateMessage: any) {
    this.navigationTabInfo = {
      latitude: deviceUpdateMessage.latitude,
      longitude: deviceUpdateMessage.longitude,
      elevation: deviceUpdateMessage.altitude,
      usedSatellites: deviceUpdateMessage.satellites,
      positionType: deviceUpdateMessage.position_type,
      baseLocationLatitude: 0,
      baseLocationLongitude: 0,
      frequency: 0,
      gpsSource: deviceUpdateMessage.gps_source,
      isSteeringEngaged: deviceUpdateMessage.isSteeringEngaged,
      deviceType: 'agronave',
    };
  }

  private createDeviceInfo(deviceUpdateMessage: any): DeviceInfoModel {
    return new DeviceInfoModel(
      deviceUpdateMessage.serial_number,
      'agronave',
      deviceUpdateMessage.task_active === true
        ? RealtimeDeviceStatus.WORKING
        : RealtimeDeviceStatus.MOVING,
      true,
      false,
      this.sectState.map((num) => String(num)),
      OpType.ISOBUS,
      this.implementWidth as number,
      this.currentRate as number,
      deviceUpdateMessage.latitude,
      deviceUpdateMessage.longitude,
      deviceUpdateMessage.timestamp,
      this.sectState.join(''),
      deviceUpdateMessage.speed,
    );
  }

  private saveHistoricalData(deviceUpdateMessage: any) {
    this.oldMessages.push({
      center: {
        lat: deviceUpdateMessage.latitude,
        lng: deviceUpdateMessage.longitude,
      },
      radius: (this.implementWidth as number) / 2,
      instRate: this.currentRate,
      sectionArr: this.sectState.map((num) => String(num)),
      timestamp: deviceUpdateMessage.timestamp,
      selected: false,
      gwAddr: 'agronave',
      velocity: deviceUpdateMessage.speed,
      opType: OpType.ISOBUS,
    });

    this.timestamps.push(deviceUpdateMessage.timestamp);
  }

  private updateLastMessage(deviceUpdateMessage: any) {
    this.lastmessage = deviceUpdateMessage;
    this.timestamps.push(deviceUpdateMessage.timestamp);
  }

  decodeBase64ToJson(base64String: string): any {
    try {
      const jsonString = atob(base64String);
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Erro ao decodificar a string Base64:', error);
      return null;
    }
  }

  fetchRealtimeMsgsPeriodically(deviceId: string): void {
    this.deviceInterval = setInterval(() => {
      this.deviceService.getDeviceRealtime(deviceId).subscribe(
        (deviceMessage: DeviceMessageModel) => {
          this.parseRealtimeMsg(deviceMessage);
        },
        (err: any) => {
          console.error(err);
        },
      );
    }, 3000);
  }

  parseRealtimeMsg(deviceMessages): Promise<void> {
    if (this.enableProcessing) {
      if (deviceMessages.length !== 0 && Array.isArray(deviceMessages)) {
        if (this.getLastMessageOnly) {
          this.loadingAgresSpinner.toHide();
          const deviceMessage = deviceMessages[deviceMessages.length - 1];
          const dinamycMessages = deviceMessages.filter((item) => 'd' in item);
          const messagesToGet = dinamycMessages.filter((item) => {
            if (!this.timestamps.includes(item.d.timestamp)) {
              return item;
            }
          });
          if (messagesToGet.length > 0) {
            if (
              this.lastmessage.d.operation.work_status !==
              messagesToGet[0].d.operation.work_status
            ) {
              window.location.reload();
            }
            if (this.lastmessage.d.timestamp !== messagesToGet[0].d.timestamp) {
              const device: DeviceMessageModel = messagesToGet[0];
              const operationType = device.d.operation.op_type;
              this.operationSidePanelInfo =
                new DynamicMessageOperationSidePanelModel(
                  operationType,
                  device.d.operation.app_vol,
                  device.d.navigation.speed,
                  device.d.operation.cover_area,
                  device.d.machine.rpm,
                );
              const activeTime: number = device.d.operation.tot_op_e;
              const totalTime: number = device.d.operation.tot_op_t;
              const sleepTime: number = totalTime - activeTime;
              const operationActiveTime = this.secondsToTime(activeTime);
              const operationTotalTime = this.secondsToTime(totalTime);
              const operationSleepTime = this.secondsToTime(sleepTime);
              const percentTime = (activeTime / totalTime) * 100;
              const operationTimePercent = Number(percentTime.toFixed(0));
              const numberKm = device.d.operation.travel_dist;
              const travelDistance = numberKm.toFixed(1);
              const sectionStatus = device.d.operation.sec_state;
              const numberOfSections = device.d.operation.sec_number;
              let sectionArr = [];

              if (Number(sectionStatus) === 0) {
                for (let i = 0; i < numberOfSections; i++) {
                  sectionArr.push('0');
                }
              } else {
                sectionArr = sectionStatus.split('');
              }

              this.operationTabInfo = new DynamicMessageOperationTabModel();
              this.operationTabInfo.implementWidth =
                device.d.operation.impl_width;
              this.operationTabInfo.numberOfSections =
                device.d.operation.sec_number;
              this.operationTabInfo.operationSleepTime = operationSleepTime;
              this.operationTabInfo.operationActiveTime = operationActiveTime;
              this.operationTabInfo.operationTotalTime = operationTotalTime;
              this.operationTabInfo.travelDistance = travelDistance;
              this.operationTabInfo.instRate = device.d.operation.inst_rate;
              this.operationTabInfo.sectionArr = sectionArr;
              this.operationTabInfo.operationTimePercent = operationTimePercent;
              this.operationTabInfo.operationType = operationType;

              this.machineTabInfo = new MachineTabModel(
                device.d.node_addr,
                device.d.machine.rpm,
                device.d.machine.engine_temp,
                device.d.machine.oil_press,
                device.d.navigation.speed,
                device.d.machine.bat_volt,
                device.d.machine.fuel_lvl,
                device.d.machine.engine_type,
              );
              if (device.d.machine.rpm !== 0) {
                this.machineTabHidden = false;
              }
              this.navigationTabInfo = new NavigationTabModel(
                device.d.navigation.location.latitude,
                device.d.navigation.location.longitude,
                device.d.navigation.location.elevation,
                device.d.navigation.used_sat,
                device.d.telemetry?.freq,
                device.d.base_location.latitude,
                device.d.base_location.longitude,
                device.d.navigation.pos_type,
              );
              const status = convertToRealtimeStatus(
                device.d.operation.work_status,
              );

              this.deviceInfo = new DeviceInfoModel(
                device.d.node_addr,
                device.d.gw_addr,
                status,
                true,
                false,
                sectionArr,
                device.d.operation.op_type,
                device.d.operation.impl_width,
                device.d.operation.inst_rate,
                device.d.navigation.location.latitude,
                device.d.navigation.location.longitude,
                device.d.timestamp,
                device.d.operation.sec_state,
                device.d.navigation.speed,
              );
              this.lastmessage = device;
              this.timestamps.push(device.d.timestamp);
            }
          }

          if ('e' in deviceMessage) {
            const device: DeviceMessageModel = deviceMessage;
            this.staticMessageOperationTabInfo =
              new StaticMessageOperationTabModel(
                device.e.crop_type,
                device.e.field_name,
                device.e.event_name,
                device.e.nozz_number,
                device.e.nozz_per_section,
                device.e.nozz_spacing,
              );
            this.staticMessageOperationSidePanelInfo =
              new StaticMessageOperationSidePanelModel(device.e.operator);
          }
          return;
        }
        for (const deviceMessage of deviceMessages) {
          if ('d' in deviceMessage) {
            this.lastmessage = deviceMessage;
            const device: DeviceMessageModel = deviceMessage;
            const operationType = device.d.operation.op_type;
            this.operationSidePanelInfo =
              new DynamicMessageOperationSidePanelModel(
                operationType,
                device.d.operation.app_vol,
                device.d.navigation.speed,
                device.d.operation.cover_area,
                device.d.machine.rpm,
              );
            const activeTime: number = device.d.operation.tot_op_e;
            const totalTime: number = device.d.operation.tot_op_t;
            const sleepTime: number = totalTime - activeTime;
            const operationActiveTime = this.secondsToTime(activeTime);
            const operationTotalTime = this.secondsToTime(totalTime);
            const operationSleepTime = this.secondsToTime(sleepTime);
            const percentTime = (activeTime / totalTime) * 100;
            const operationTimePercent = Number(percentTime.toFixed(0));
            const numberKm = device.d.operation.travel_dist;
            const travelDistance = numberKm.toFixed(1);
            const sectionStatus = device.d.operation.sec_state;
            const numberOfSections = device.d.operation.sec_number;
            let sectionArr = [];

            if (Number(sectionStatus) === 0) {
              for (let i = 0; i < numberOfSections; i++) {
                sectionArr.push('0');
              }
            } else {
              sectionArr = sectionStatus.split('');
            }

            this.operationTabInfo = new DynamicMessageOperationTabModel();
            this.operationTabInfo.implementWidth =
              device.d.operation.impl_width;
            this.operationTabInfo.numberOfSections =
              device.d.operation.sec_number;
            this.operationTabInfo.operationSleepTime = operationSleepTime;
            this.operationTabInfo.operationActiveTime = operationActiveTime;
            this.operationTabInfo.operationTotalTime = operationTotalTime;
            this.operationTabInfo.travelDistance = travelDistance;
            this.operationTabInfo.instRate = device.d.operation.inst_rate;
            this.operationTabInfo.sectionArr = sectionArr;
            this.operationTabInfo.operationTimePercent = operationTimePercent;
            this.operationTabInfo.operationType = operationType;

            this.machineTabInfo = new MachineTabModel(
              device.d.node_addr,
              device.d.machine.rpm,
              device.d.machine.engine_temp,
              device.d.machine.oil_press,
              device.d.navigation.speed,
              device.d.machine.bat_volt,
              device.d.machine.fuel_lvl,
              device.d.machine.engine_type,
            );
            this.navigationTabInfo = new NavigationTabModel(
              device.d.navigation.location.latitude,
              device.d.navigation.location.longitude,
              device.d.navigation.location.elevation,
              device.d.navigation.used_sat,
              device.d.telemetry?.freq,
              device.d.base_location.latitude,
              device.d.base_location.longitude,
              device.d.navigation.pos_type,
            );
            this.oldMessages.push({
              center: {
                lat: device.d.navigation.location.latitude,
                lng: device.d.navigation.location.longitude,
              },
              radius: device.d.operation.impl_width / 2,
              instRate: device.d.operation.inst_rate,
              sectionArr: sectionArr,
              timestamp: device.d.timestamp,
              selected: false,
              gwAddr: device.d.gw_addr,
              velocity: device.d.navigation.speed,
            });
            this.timestamps.push(device.d.timestamp);
          } else if ('e' in deviceMessage) {
            const device: DeviceMessageModel = deviceMessage;
            this.staticMessageOperationTabInfo =
              new StaticMessageOperationTabModel(
                device.e.crop_type,
                device.e.field_name,
                device.e.event_name,
                device.e.nozz_number,
                device.e.nozz_per_section,
                device.e.nozz_spacing,
              );
            this.staticMessageOperationSidePanelInfo =
              new StaticMessageOperationSidePanelModel(device.e.operator);
          }
        }
        this.circles = this.oldMessages;
        this.enableProcessing = false;
      }
    }
  }

  onProcessingComplete() {
    if (this.deviceInfoArray.length > 0) {
      this.deviceInfo = this.deviceInfoArray.shift();
    } else {
      this.deviceInfoArray = [];
      this.enableProcessing = true;
      this.getLastMessageOnly = true;
    }
  }

  secondsToTime(seconds: number): string {
    const totalSeconds: number = seconds;
    const hours: number = Math.floor(totalSeconds / 3600);
    const minutes: number = Math.floor((totalSeconds - hours * 3600) / 60);
    let result: string = String(hours < 10 ? '0' + hours : hours);
    result += ':' + (minutes < 10 ? '0' + minutes : minutes);
    return result;
  }

  dashboard() {
    this.router.navigate(['/']);
  }
  goBack() {
    this._location.back();
  }
}
