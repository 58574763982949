import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INTERCEPTInternalServerError } from 'app/core/interceptors/contexts';
import { ServiceBase } from 'app/core/services/service.base';
import { Weather } from 'app/shared/models/weather';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DeviceService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getAllDeviceRealtime(): Observable<any> {
    return this.http
      .get(this.UrlServiceV1 + 'navigation', {
        ...this.obterAuthHeader(),
        context: new HttpContext().set(INTERCEPTInternalServerError, false),
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getAllAgronaveDevicesRealtime(): Observable<any> {
    return this.http
      .get(this.UrlServiceV1 + 'navigation-agronave', {
        ...this.obterAuthHeader(),
        context: new HttpContext().set(INTERCEPTInternalServerError, false),
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDeviceRealtime(deviceId: string): Observable<any> {
    return this.http
      .get<any>(this.UrlServiceV1 + `navigation?` + `deviceId=${deviceId}`, {
        ...this.obterAuthHeader(),
        context: new HttpContext().set(INTERCEPTInternalServerError, false),
      })
      .pipe(catchError(this.serviceError));
  }

  getTaskInfoMessages(deviceId: string): Observable<any> {
    return this.http
      .get<any>(
        this.UrlServiceV1 +
          `navigation-agronave?` +
          `deviceId=${deviceId}&` +
          `topicType=taskInfo`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }

  getTaskDDOPMessages(deviceId: string): Observable<any> {
    return this.http
      .get<any>(
        this.UrlServiceV1 +
          `navigation-agronave?` +
          `deviceId=${deviceId}&` +
          `topicType=taskDDOP`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }

  getUpdateMessages(deviceId: string): Observable<any> {
    return this.http
      .get<any>(
        this.UrlServiceV1 +
          `navigation-agronave?` +
          `deviceId=${deviceId}&` +
          `topicType=update`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }

  ddopParsed(message: string): Observable<any> {
    return this.http
      .post<any>(
        this.UrlServiceV1 + `ddop-parser`,
        message,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
