export type OperationType = OpType | OpTypeLegacy;

export enum OpType {
  SPRAYING = 0,
  FERTILIZATION,
  HARVEST,
  PLANTING,
  IRRIGATION,
  OTHER,
  ORCHARDING,
  ISOBUS,
}

export enum OpTypeLegacy {
  SPRAYING = 'PULVERIZATION',
  FERTILIZATION = 'FERTILIZATION',
  HARVEST = 'HARVEST',
  PLANTING = 'SEEDING',
  IRRIGATION = 'IRRIGATION',
  ISOBUS = 'ISOBUS',
  OTHER = 'OTHER',
}
